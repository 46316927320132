// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Users
export const getUsers = createAsyncThunk('users/getUsers', async (params, { rejectWithValue }) => {
  const { contract_id, ...other } = params;

  try {
    const response = await http.get(apiConfig.getUsersEndpoint.replace(':id', contract_id), {
      params: {
        ...other,
      },
    });
    const data = response.data;

    return {
      users: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Invites
export const getInvites = createAsyncThunk('users/getInvites', async (params, { rejectWithValue }) => {
  const { filters, ...other } = params;

  let formattedFilters = '';
  filters.map(
    (item, index) =>
      (formattedFilters += `${index === 0 ? '?' : '&'}content_type[${index}]=${item.content_type}&object_id[${index}]=${item.object_id}`),
  );

  try {
    const response = await http.get(apiConfig.getInvitesEndpoint + formattedFilters, {
      params: {
        ...other,
      },
    });
    const data = response.data;

    return {
      invites: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Invite user
export const inviteUser = createAsyncThunk('users/inviteUser', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.inviteUserEndpoint, {
      email: params.email,
      metadata: {
        objects: [
          {
            content_type_id: params.content_type_id,
            object_id: params.contract,
            roles: [params.role],
          },
        ],
      },
    });
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Invite
export const deleteInvite = createAsyncThunk('users/deleteInvite', async (params, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await http.delete(apiConfig.deleteInviteEndpoint.replace(':id', params.id));
    const data = response.data;

    dispatch(getInvites(getState().users.invitesFilters));

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoadingUsers: false,
    isLoadingInvites: false,
    users: [],
    invites: [],
    usersCount: 0,
    invitesCount: 0,
    usersError: false,
    invitesError: false,
    usersFilters: {
      page: 1,
      page_size: 25,
    },
    invitesFilters: {
      page: 1,
      page_size: 25,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoadingUsers = true;
        state.usersError = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.usersCount = action.payload.count;
        state.usersFilters = action.payload.filters;

        state.isLoadingUsers = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoadingUsers = false;
        state.users = [];
        state.usersCount = 0;

        state.usersError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getInvites.pending, (state) => {
        state.isLoadingInvites = true;
        state.invitesError = false;
      })
      .addCase(getInvites.fulfilled, (state, action) => {
        state.invites = action.payload.invites;
        state.invitesCount = action.payload.count;
        state.invitesFilters = action.payload.filters;

        state.isLoadingInvites = false;
      })
      .addCase(getInvites.rejected, (state, action) => {
        state.isLoadingInvites = false;
        state.invites = [];
        state.invitesCount = 0;

        state.invitesError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export default usersSlice.reducer;
