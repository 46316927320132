// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Tasks
export const getTasks = createAsyncThunk('tasks/getTasks', async (params, { rejectWithValue }) => {
  const { ...other } = params;

  for (const key of Object.keys(other)) {
    if (other[key] === '') {
      delete other[key];
    }
  }

  try {
    const response = await http.get(apiConfig.getTasksEndpoint, {
      params: {
        ...other,
      },
    });
    const data = response.data;

    return {
      tasks: data.results || [],
      count: data.count || 0,
      filters: params || {},
    };
  } catch (error) {
    return rejectWithValue({
      error: error.response.data,
      filters: params || {},
    });
  }
});

// ** Delete Task
export const deleteTask = createAsyncThunk('tasks/deleteTask', async (params, { rejectWithValue, dispatch, getState }) => {
  const { id, task } = params;

  try {
    const response = await http.delete(apiConfig.deleteTicketTaskEndpoint.replace(':id', id).replace(':task', task));
    const data = response.data;

    dispatch(getTasks(getState().tasks.tasksFilters));

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Task
export const editTask = createAsyncThunk('tasks/editTask', async (params, { rejectWithValue, dispatch, getState }) => {
  const { id, task, ...other } = params;

  try {
    const response = await http.patch(apiConfig.editTicketTaskEndpoint.replace(':id', id).replace(':task', task), other);
    const data = response.data;

    dispatch(getTasks(getState().tasks.tasksFilters));

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    isLoadingTasks: false,
    tasks: [],
    tasksCount: 0,
    tasksError: false,
    tasksFilters: {
      page: 1,
      page_size: 25,
      user: undefined,
      author: undefined,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.isLoadingTasks = true;
        state.tasksError = false;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.tasks;
        state.tasksCount = action.payload.count;
        state.tasksFilters = action.payload.filters;

        state.isLoadingTasks = false;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.tasks = [];
        state.tasksCount = 0;
        state.tasksFilters = action.payload.filters;
        state.tasksError = action.error?.payload?.error?.detail || 'Произошла ошибка';

        state.isLoadingTasks = false;
      });
  },
});

export default tasksSlice.reducer;
