export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/api/',
  getContractsEndpoint: '/v1/dashboard/contracts/',
  getContractEndpoint: '/v1/dashboard/contracts/:id/',
  editContractEndpoint: '/v1/dashboard/contracts/:id/',
  purgeSpamContractEndpoint: '/v1/dashboard/contracts/:id/purge_spam/',
  getTicketsEndpoint: '/v1/dashboard/tickets/',
  getTicketEndpoint: '/v1/dashboard/tickets/:id/',
  editTicketEndpoint: '/v1/dashboard/tickets/:id/',
  deleteTicketEndpoint: '/v1/dashboard/tickets/:id/',
  createTicketEndpoint: '/v1/tickets/',
  getTicketStatusesEndpoint: '/v1/dashboard/ticket-statuses/',
  getTicketActivitiesEndpoint: '/v1/dashboard/ticket-logs/',
  addTicketCommentEndpoint: '/v1/dashboard/ticket-logs/',
  deleteTicketCommentEndpoint: '/v1/dashboard/ticket-logs/:id/',
  getTicketFormsEndpoint: '/v1/dashboard/ticket-forms/',
  getTicketFormEndpoint: '/v1/dashboard/ticket-forms/:id/',
  editTicketFormEndpoint: '/v1/dashboard/ticket-forms/:id/',
  deleteTicketFormEndpoint: '/v1/dashboard/ticket-forms/:id/',
  createTicketFormEndpoint: '/v1/dashboard/ticket-forms/',
  getTicketChannelSourcesEndpoint: '/v1/dashboard/ticket-channel-sources/',
  getTicketChannelSourceEndpoint: '/v1/dashboard/ticket-channel-sources/:id/',
  editTicketChannelSourceEndpoint: '/v1/dashboard/ticket-channel-sources/:id/',
  deleteTicketChannelSourceEndpoint: '/v1/dashboard/ticket-channel-sources/:id/',
  createTicketChannelSourceEndpoint: '/v1/dashboard/ticket-channel-sources/',
  getTicketChannelsEndpoint: '/v1/dashboard/ticket-channels/',
  getTicketChannelEndpoint: '/v1/dashboard/ticket-channels/:id/',
  editTicketChannelEndpoint: '/v1/dashboard/ticket-channels/:id/',
  deleteTicketChannelEndpoint: '/v1/dashboard/ticket-channels/:id/',
  createTicketChannelEndpoint: '/v1/dashboard/ticket-channels/',
  getBotInfoEndpoint: '/v1/dashboard/bot/info/',
  getUsersEndpoint: '/v1/dashboard/contracts/:id/users/',
  getInvitesEndpoint: '/v1/dashboard/invitations/',
  inviteUserEndpoint: '/v1/dashboard/invitations/',
  deleteInviteEndpoint: '/v1/dashboard/invitations/:id/',
  getContentTypesEndpoint: '/v1/dashboard/content-types/',
  getRolesEndpoint: '/v1/dashboard/roles/',
  getCommentsVisibilityLevelsEndpoint: '/v1/dashboard/ticket-logs/visibility_levels/',
  getTicketTasksEndpoint: '/v1/dashboard/tickets/:id/tasks/',
  createTicketTaskEndpoint: '/v1/dashboard/tickets/:id/tasks/',
  editTicketTaskEndpoint: '/v1/dashboard/tickets/:id/tasks/:task/',
  deleteTicketTaskEndpoint: '/v1/dashboard/tickets/:id/tasks/:task/',
  getTasksEndpoint: '/v1/dashboard/tasks/',
};
