// ** React
import { createContext, useEffect, useState } from 'react';

// ** Components
import Spinner from 'src/@core/components/Spinner';

// ** Http
import { http } from 'src/@core/utils/http';

// ** Config
import authConfig from 'src/configs/auth';

// ** Utils
import localStorageService from 'src/@core/utils/local-storage';

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  acceptInvite: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [user, setUser] = useState(defaultProvider.user);

  // ** Effects
  useEffect(() => {
    const initAuth = async () => {
      const storedToken = localStorageService.get('accessToken');
      if (storedToken) {
        setLoading(true);

        await http
          .get(authConfig.meEndpoint)
          .then(async (response) => {
            setUser({ ...response.data });
            setLoading(false);
          })
          .catch(() => {
            handleLogout();
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  // ** Handlers
  const handleLogin = (params, errorCallback, successCallback) => {
    http
      .post(authConfig.loginEndpoint, params)
      .then(async (res) => {
        localStorageService.set('accessToken', res.data.access);
        localStorageService.set('refreshToken', res.data.refresh);
      })
      .then(() => {
        http
          .get(authConfig.meEndpoint)
          .then(async (response) => {
            setUser({ ...response.data });
          })
          .then(() => {
            successCallback && successCallback();
          });
      })
      .catch((err) => {
        const error = err;
        errorCallback ? errorCallback(error.response?.data) : null;
      });
  };

  const handleLogout = () => {
    setUser(null);

    localStorageService.remove('accessToken');
    localStorageService.remove('refreshToken');
  };

  const handleAcceptInvite = (params, errorCallback, successCallback) => {
    http
      .post(authConfig.acceptInviteEndpoint, {
        token: params.token,
        password1: params.password1,
        password2: params.password2,
      })
      .then(() => {
        handleLogin({ email: params.email, password: params.password1 }, errorCallback, successCallback);
      })
      .catch((err) => {
        const error = err;
        errorCallback ? errorCallback(error.response?.data) : null;
      });
  };

  const values = {
    loading,
    user,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    acceptInvite: handleAcceptInvite,
  };

  if (loading) return <Spinner />;

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
