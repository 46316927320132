// ** Toolkit
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import contracts from './contracts';
import tickets from './tickets';
import ticketForms from './ticket-forms';
import ticketChannelSources from './ticket-channel-sources';
import ticketChannels from './ticket-channels';
import users from './users';
import tasks from './tasks';
import helpers from './helpers';

export const store = configureStore({
  reducer: {
    contracts,
    tickets,
    ticketForms,
    ticketChannelSources,
    ticketChannels,
    users,
    tasks,
    helpers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
