// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Ticket Statuses
export const getTicketStatuses = createAsyncThunk('helpers/getTicketStatuses', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketStatusesEndpoint, {
      params: {
        page_size: 999,
        contract: params.contract,
      },
    });
    const data = response.data;

    return {
      ticketStatuses: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Contracts
export const getContracts = createAsyncThunk('helpers/getContracts', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getContractsEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      contracts: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Ticket Forms
export const getTicketForms = createAsyncThunk('helpers/getTicketForms', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketFormsEndpoint, {
      params: {
        page_size: 999,
        contract: params.contract,
      },
    });
    const data = response.data;

    return {
      ticketForms: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Ticket Channel Sources
export const getTicketChannelSources = createAsyncThunk(
  'helpers/getTicketChannelSources',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getTicketChannelSourcesEndpoint, {
        params: {
          page_size: 999,
          contract: params.contract,
        },
      });
      const data = response.data;

      return {
        ticketChannelSources: data.results || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// ** Get Ticket Channels
export const getTicketChannels = createAsyncThunk('helpers/getTicketChannels', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getTicketChannelsEndpoint, {
      params: {
        page_size: 999,
        contract: params.contract,
      },
    });
    const data = response.data;

    return {
      ticketChannels: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Content Types
export const getContentTypes = createAsyncThunk('helpers/getContentTypes', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getContentTypesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      contentTypes: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Roles
export const getRoles = createAsyncThunk('helpers/getRoles', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getRolesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      roles: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Comments Visibility Levels
export const getCommentsVisibilityLevels = createAsyncThunk(
  'helpers/getCommentsVisibilityLevels',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await http.get(apiConfig.getCommentsVisibilityLevelsEndpoint, {
        params: {
          page_size: 999,
        },
      });
      const data = response.data;

      return {
        commentsVisibilityLevels: data || [],
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    isLoadingTicketForms: false,
    isLoadingTicketStatuses: false,
    isLoadingContracts: false,
    isLoadingTicketChannelSources: false,
    isLoadingTicketChannels: false,
    isLoadingContentTypes: false,
    isLoadingRoles: false,
    isLoadingCommentsVisibilityLevels: false,
    ticketForms: [],
    ticketStatuses: [],
    contracts: [],
    ticketChannelSources: [],
    ticketChannels: [],
    contentTypes: [],
    roles: [],
    commentsVisibilityLevels: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTicketForms.pending, (state) => {
        state.isLoadingTicketForms = true;
      })
      .addCase(getTicketForms.fulfilled, (state, action) => {
        state.ticketForms = action.payload.ticketForms;

        state.isLoadingTicketForms = false;
      })
      .addCase(getTicketForms.rejected, (state) => {
        state.isLoadingTicketForms = false;
      });
    builder
      .addCase(getTicketStatuses.pending, (state) => {
        state.isLoadingTicketStatuses = true;
      })
      .addCase(getTicketStatuses.fulfilled, (state, action) => {
        state.ticketStatuses = action.payload.ticketStatuses;

        state.isLoadingTicketStatuses = false;
      })
      .addCase(getTicketStatuses.rejected, (state) => {
        state.isLoadingTicketStatuses = false;
      });
    builder
      .addCase(getContracts.pending, (state) => {
        state.isLoadingContracts = true;
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.contracts = action.payload.contracts;

        state.isLoadingContracts = false;
      })
      .addCase(getContracts.rejected, (state) => {
        state.isLoadingContracts = false;
      });
    builder
      .addCase(getTicketChannelSources.pending, (state) => {
        state.isLoadingTicketChannelSources = true;
      })
      .addCase(getTicketChannelSources.fulfilled, (state, action) => {
        state.ticketChannelSources = action.payload.ticketChannelSources;

        state.isLoadingTicketChannelSources = false;
      })
      .addCase(getTicketChannelSources.rejected, (state) => {
        state.isLoadingTicketChannelSources = false;
      });
    builder
      .addCase(getTicketChannels.pending, (state) => {
        state.isLoadingTicketChannels = true;
      })
      .addCase(getTicketChannels.fulfilled, (state, action) => {
        state.ticketChannels = action.payload.ticketChannels;

        state.isLoadingTicketChannels = false;
      })
      .addCase(getTicketChannels.rejected, (state) => {
        state.isLoadingTicketChannels = false;
      });
    builder
      .addCase(getContentTypes.pending, (state) => {
        state.isLoadingContentTypes = true;
      })
      .addCase(getContentTypes.fulfilled, (state, action) => {
        state.contentTypes = action.payload.contentTypes;

        state.isLoadingContentTypes = false;
      })
      .addCase(getContentTypes.rejected, (state) => {
        state.isLoadingContentTypes = false;
      });
    builder
      .addCase(getRoles.pending, (state) => {
        state.isLoadingRoles = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload.roles;

        state.isLoadingRoles = false;
      })
      .addCase(getRoles.rejected, (state) => {
        state.isLoadingRoles = false;
      });
    builder
      .addCase(getCommentsVisibilityLevels.pending, (state) => {
        state.isLoadingCommentsVisibilityLevels = true;
      })
      .addCase(getCommentsVisibilityLevels.fulfilled, (state, action) => {
        state.commentsVisibilityLevels = action.payload.commentsVisibilityLevels;

        state.isLoadingCommentsVisibilityLevels = false;
      })
      .addCase(getCommentsVisibilityLevels.rejected, (state) => {
        state.isLoadingCommentsVisibilityLevels = false;
      });
  },
});

export default helpersSlice.reducer;
